import { commonItemInterestFree } from './commonItemFinancing';
import commonItems from './commonItems';

const saleInstallmentPercent = '12';

const MLB = (i18n) => [
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Vende al contado o en cuotas con interés'),
    value: '1,99%',
    icon: '',
  },
  {
    label: commonItemInterestFree(i18n, saleInstallmentPercent),
    value: '11,99%',
    icon: '',
  },
  ...commonItems(i18n),
];

export default MLB;
