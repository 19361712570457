import {
  commonItemFinancing,
  commonItemInterestFree,
} from './commonItemFinancing';
import commonItemPercent from './commonItemPercent';
import commonItems from './commonItems';

const salePercent = '3,7%';
const installment = '12';
const saleInstallmentPercent = '11,99%';

const MCO = (i18n) => [
  {
    label: commonItemFinancing(i18n),
    value: commonItemPercent(i18n, salePercent),
    icon: '',
  },
  {
    label: commonItemInterestFree(i18n, installment),
    value: commonItemPercent(i18n, saleInstallmentPercent),
    icon: '',
  },
  ...commonItems(i18n),
];

export default MCO;
