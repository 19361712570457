import { commonItemInterestFree } from './commonItemFinancing';
import commonItemPercent from './commonItemPercent';
import commonItems from './commonItems';

const salePercent = '2,99% ';
const installment = '12';
const saleInstallmentPercent = '9%';

const MLC = (i18n) => [
  {
    /* i18n-next-line except: ["pt-BR", "es-AR"] */
    label: i18n.gettext('Vende en un pago o en cuotas con interés'),
    value: commonItemPercent(i18n, salePercent),
    icon: '',
  },
  {
    label: commonItemInterestFree(i18n, installment),
    value: commonItemPercent(i18n, saleInstallmentPercent),
    icon: '',
  },
  ...commonItems(i18n),
];

export default MLC;
