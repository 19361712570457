export const faqTitle = (i18n) => i18n.gettext('Preguntas frecuentes');
export const ecommerceSpecialist = (i18n) =>
  i18n.gettext('1 especialista en ecommerce');
export const freeExclamation = (i18n) => i18n.gettext('¡Gratis!');
export const prices = (i18n) => i18n.gettext('Precios');
export const contactUs = (i18n) => i18n.gettext('Contáctanos');
export const whatsAppAtention = (i18n) => i18n.gettext('Atención vía Whatsapp');
export const priceMonth = (i18n, price) =>
  i18n.gettext('+ {0} {1} /mes', price).replace(' ', '');
export const functionalities = (i18n) => i18n.gettext('funcionalidades');
