const freeWord = (i18n) => i18n.gettext('Gratis');

const commonItems = (i18n) => [
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Costos fijos por plataforma'),
    value: freeWord(i18n),
    icon: '',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Costo por transacción en Mercado Pago'),
    value: freeWord(i18n),
    icon: '',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Ventas, visitas y publicaciones ilimitadas'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Logística con Envíos de Mercado Libre'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Programa Compra Protegida'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Soporte personalizado'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Herramientas de marketing digital'),
    /* i18n-next-line except: ["es-AR"] */
    value: i18n.gettext('Desde el primer momento'),
    icon: '',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Métricas y recomendaciones personalizadas'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Colaboradores ilimitados para gestionar tu tienda'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Permisos diferenciados por colaborador'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Posicionamiento SEO guiado por AI'),
    value: '',
    icon: 'Check',
  },
  {
    label: i18n.gettext('Registro de visitantes'),
    value: '',
    icon: 'Check',
  },
  {
    label: i18n.gettext('Recupero de carritos abandonados'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Códigos de descuento'),
    value: '',
    icon: 'Check',
  },
  {
    label: i18n.gettext('Productos relacionados'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["pt-BR", "es-AR"] */
    label: i18n.gettext(
      'Creación de anuncios en Meta Ads, Tik Tok y Google Ads en simples pasos',
    ),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Monitoreo de visitas con Google Analytics 4'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Mercado Ads incluido en tu Shop'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Integración con WhatsApp Business'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Vender con BioLibre'),
    value: '',
    icon: 'Check',
  },
  {
    label: i18n.gettext('Vender en Mercado Libre'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Diseño personalizable'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Dominio propio'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Mercado Pago Point para tu tienda física'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Descuento en Envíos Gratis con Mercado Libre'),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext(
      'Acceso a préstamos inmediatos con Créditos de Mercado Libre',
    ),
    value: '',
    icon: 'Check',
  },
  {
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Aplicaciones Certificadas'),
    value: '',
    icon: 'Check',
  },
];

export default commonItems;
